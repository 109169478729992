@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200&display=swap");

body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto Mono", monospace;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
